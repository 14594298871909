/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "15-discount-for-us-veterans-who-choose-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#15-discount-for-us-veterans-who-choose-hearcom",
    "aria-label": "15 discount for us veterans who choose hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "15% discount for U.S. Veterans who choose hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*At the moment, the VA provides free hearing aids for U.S. veterans. To be eligible for this benefit, you must meet certain requirements. The best way to find out if you have any applicable VA benefits is to contact your nearest VA clinic. Below you will find general information about hearing loss and military veterans, VA benefits, eligibility, and our special hear.com offer for former military personnel."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we provide comprehensive support and guidance. As a thank you for your service, we also give you a special veteran’s discount. In some cases, we can apply a 15% discount on whichever one of our brand-name hearing aids you would like."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "52-of-soldiers-have-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#52-of-soldiers-have-hearing-loss",
    "aria-label": "52 of soldiers have hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "52% of soldiers have hearing loss"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Veterans2.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss among military personnel is a common occurrence. It is reported that approximately 59,000 veterans who were involved in Operation Iraqi Freedom and Operation Enduring Freedom have some degree of loss in one ear (or both). People who were near areas of combat were likely exposed to loud noises, thus damaging critical parts of the ear responsible for our sense of hearing. In fact, 52% of soldiers who actively fought in the military have a hearing loss that is characterized as moderately severe or worse. This loss is considered “noise-induced.” Things like the sound of a rifle or gun going off, explosions, and loud machinery can cause hearing loss. Additional contributing factors include lack of ear protection (e.g. ear plugs), direct ear or head injury, and/or family history.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Injuries don’t have to be visible to affect a person’s quality of life. That is why our military veterans need appropriate care and support when they have difficulties hearing. Thankfully, the U.S. Department of Veterans Affairs (VA) supports our troops in providing hearing aid coverage for those who need it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-va-and-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-va-and-hearing-loss",
    "aria-label": "the va and hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The VA and hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The VA offers many benefits for those individuals who qualify for them. These benefits include services like hearing evaluations and other preventive resources. Free hearing aids for seniors is still a work in progress in the U.S. Currently, services like Medicare and Medicaid provide little to no coverage of hearing tests and hearing aids. Moreover, some health insurance companies include hearing aid benefits in their plans, but how much they cover depends on several factors. However, the VA provides military veterans who are experiencing some degree of hearing loss with assistive listening devices. Depending on your specific situation, VA hearing aid benefits may or may not include full or partial coverage of the devices, and may vary from state to state. Eligibility for a hearing aid benefit differs from person to person. We highly recommend you give the VA a call to find out what your exact benefits are and if hearing aids are included."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have health insurance and are in need of hearing aids, we can verify if you have a benefit. At hear.com, we have a team of insurance specialists who can quickly check your coverage and provide you with more information. If cost is an issue, speak to one of our hearing aid experts to learn more about financial assistance for hearing aids."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "eligibility-for-free-hearing-aids-through-the-va",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#eligibility-for-free-hearing-aids-through-the-va",
    "aria-label": "eligibility for free hearing aids through the va permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Eligibility for free hearing aids through the VA"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If it’s hard to hear your loved ones, and you are a U.S. military veteran, chances are, you have a hearing loss. As mentioned before, the best thing for you to do is check your financial benefits with the VA. Below you will find a quick reference guide of the eligibility requirements for free hearing aids and other health services."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Veterans with a compensable disability related to their service in the military"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Former Prisoners of War (POWs)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Veterans who were awarded a Purple Heart for their service"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Veterans who are currently receiving benefits under Title 38 United States Code"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Veterans who qualify for a pension increase based on being homebound permanently and in need of around-the-clock aid. Veterans with cognitive and functional difficulties"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Veterans with hearing loss so severe it prevents them from participating in other medical treatments."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are just a few of the requirements listed in on the ", React.createElement("a", {
    href: "https://www.va.gov/opa/publications/benefits_book/benefits_chap01.asp",
    target: "_blank"
  }, "VA’s website"), ". To confirm if you meet all the necessary requirements, please contact your nearest VA health care facility. There may not be free hearing aids for seniors yet, but our veterans deserve to receive this kind of coverage for sacrificing so much for our country."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-salutes-our-troops",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-salutes-our-troops",
    "aria-label": "hearcom salutes our troops permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com salutes our troops!"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/tb247-american-flag-216x165.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are veteran or an active duty U.S. military personnel, we want to personally thank you for serving our country and protecting our freedoms. You should get top-quality support for your hearing loss. However, we know how difficult it can be at times to get immediate help from the VA. In fact, many of our customers decide to go through hear.com because it’s faster and easier to get hearing aids. Not only do we provide comprehensive support and guidance, we also go out of our way to give you a special veteran’s discount. In some cases, we can apply a 15% discount on whichever one of our brand-name hearing aids you would like, if you decide not to go through the VA.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is an exclusive hear.com offer ONLY for the outstanding individuals like yourself who courageously defended our nation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can call us at your earliest convenience, or simply fill out the questionnaire on this page, and we will reach out to you shortly. There is no obligation when you contact us. We won’t ask you to commit to anything or give us any money for your consultation. You will receive FREE, expert advice from one of our experienced hearing specialists."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We look forward to speaking with you soon; and on behalf of hear.com, thank you for your service!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try the latest hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
